import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import RealEstateImage from '../assets/images/real-estate.svg';
// import LogoBlack from '../assets/images/logo-black.svg';
// import Accurate from '../assets/images/accurate.svg';
// import Timely from '../assets/images/timely.svg';
// import Cloud from '../assets/images/cloud.svg';
import Arrow from '../assets/images/arrow.svg';

// import openEscrowExample from '../assets/images/open-escrow-example.svg';

// import EnhancedField from '../components/EnhancedField';
// import VerticalTabs from '../components/VerticalTabs';

import styles from './index.module.scss';

const FeatureCard = ({ title, content, number }) => (
  <Grid item md={4} sm={6} xs={12}>
    <Card className={styles.featureCard}>
      {/* <CardMedia
        image={Image}
        component="img"
        classes={{ media: styles.featureImg }}
      /> */}
      <div className={styles.featuredStep}>
        <div className={styles.numberedStep}>{number}</div>
      </div>
      <Typography variant="h4" className={styles.center}>{title}</Typography>
      <CardContent>
        <Typography variant="subtitle1" align="center">{content}</Typography>
      </CardContent>
    </Card>
  </Grid>
)

export default class Index extends React.Component {
  render() {
    const { data } = this.props;

    return (
      <Layout>
        <Helmet>
          <title>Replaix</title>
          <meta
            name="description"
            content="Replaix is a cloud-based email marketing platform for real estate."
          />
          <link rel="canonical" href="https://replaix.com"/>
        </Helmet>
        <section className={styles.section}>
          <Grid container justify="center">
            <Grid item xs={10}>
              <Grid container alignItems="center">
                {/* <Grid item xs={1} className={styles.offset}></Grid> */}
                <Grid item xs={12} md={3} className={styles.topSection}>
                  <Typography
                    variant="subtitle1"
                    style={{ fontSize: '28px' }}
                  >
                    Email Marketing for Real Estate
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    component="a"
                    href="/app/auth/sign-up"
                  >
                    Sign up for Free
                  </Button>
                  <Typography variant="body1" style={{ marginTop: '15px' }}>
                    Already using Replaix? <a href="/app/auth/login">Login</a>
                  </Typography>
                </Grid>
                <Grid item xs={false} md={1}></Grid>
                <Grid item md={8}>
                  <img src={RealEstateImage} alt="real estate" width="100%"/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </section>
        {/* <Grid container style={{ background: '#f5f5f5' }}>
          <Grid item xs={10} style={{ margin: '64px auto'}}>
            <Typography
              variant="h2"
              className={styles.sectionHeader}
              style={{ marginBottom: '48px' }}
            >
              How it Works
            </Typography>
            <VerticalTabs items={[
              {
                name: 'Sign Up',
                content: <Img fluid={data.signUp.childImageSharp.fluid}/>
              },
              {
                name: 'Complete Your Form',
                content: <Img fluid={data.escrowForm.childImageSharp.fluid}/>
              },
              {
                name: 'Email/Save/Print',
                content: <Img fluid={data.emailAction.childImageSharp.fluid}/>
              },
              {
                name: 'Done!',
                content: (
                  <div className={styles.openEscrowExample}>
                    <img src={openEscrowExample} alt="Open Escrow Example"/>
                  </div>
                )
              }
            ]}/>
          </Grid>
        </Grid> */}
        <Grid container className={styles.featuresContainer}>
          <Grid item xs={10}>
            <Grid spacing={3} container justify="center">
              <Grid item xs={12} className={styles.secondaryTitles}>
                <Typography
                  variant="h2"
                  className={styles.sectionHeader}
                  style={{ color: '#fff' }}
                >
                  3 Easy Steps
                </Typography>
              </Grid>
              <FeatureCard
                title="Upload"
                content="Add your contacts one by one, or all at once with a csv file."
                // image={Accurate}
                number="1"
              />
              <FeatureCard
                title="Compose"
                content="Write a hand-crafted message reminding your past clients and prospects that you are here to serve them."
                number="2"
              />
              <FeatureCard
                title="Schedule"
                content="Select the hour, day(s), week(s), and months of your scheduled mailings one time, and your mailings will go out automatically. Set it and forget it."
                // image={Cloud}
                number="3"
              />
            </Grid>
          </Grid>
        </Grid>
        <section className={styles.section}>
          <Grid container justify="center">
            <Grid item lg={10}>
              <Typography
                variant="h2"
                className={styles.sectionHeader}
              >
                Email Marketing for Real Estate
              </Typography>
            </Grid>
            <Grid container>
              <Grid item style={{
                position: 'relative',
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'center',
                width: '100%',
                left: '-53px'
              }}>
                <img src={Arrow} alt="arrow" style={{
                  display: 'block',
                  maxHeight: '115px',
                  paddingRight: '15px'
                }}/>
                <Button
                  component="a"
                  href="/app/auth/sign-up"
                  variant="contained"
                  color="secondary"
                  size="large"
                  style={{ marginTop: '96px' }}
                >
                  Try us out
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </section>
        {/* <section>
          <EnhancedField name="bunk" display={['horizontal']}/>
        </section> */}
      </Layout>
    );
  }
}

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const query = graphql`
  query {
    signUp: file(relativePath: { eq: "demo/sign-up.png" }) {
      ...fluidImage
    }
    escrowForm: file(relativePath: { eq: "demo/escrow-form.png" }) {
      ...fluidImage
    }
    emailAction: file(relativePath: { eq: "demo/email-action.png" }) {
      ...fluidImage
    }
    completedForm: file(relativePath: { eq: "demo/completed-form.png" }) {
      ...fluidImage
    }
  }
`